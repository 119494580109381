<template>
  <div></div>
</template>
<script>
import { ResetPwd } from "@/api/company";
import { isnull } from "@/assets/js/index";
import utils from "@/assets/js/utils";
import global from "@/assets/js/globalconfig";
export default {
  props: {},
  data() {
    return {
      userinfo: "",
    };
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
</style>