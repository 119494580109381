<template>
	<div>
		<div class="Ctitle">安全中心</div>

		<div class="saveBox">
			<div class="saveLine flex1">
				<div class="saveIcon">
					<img src="../../assets/img/mmdl.png" alt="" />
				</div>
				<div class="saveTitle">密码登录</div>
				<div class="saveCont already">
					互联网账号存在被盗风险，建议您定期更改密码以保护账号安全
				</div>
				<div class="saveEdit" @click="passwordedit = true">修改</div>
			</div>

			<div class="saveBTN" @click="loingout">退出登录</div>
		</div>

		<base-drawer :visible.sync="passwordedit" :size="460" title="密码修改">
			<div slot="header">
				<el-button type="primary" v-preventReClick @click="pwdEdit">提交</el-button>
			</div>
			<el-form label-width="80px">
				<el-form-item label="旧密码:">
					<el-input v-model="password.oldpassword" type="password" placeholder="请输入旧密码"></el-input>
				</el-form-item>
				<el-form-item label="新密码:">
					<el-input v-model="password.password" type="password" placeholder="请输入新密码"></el-input>
				</el-form-item>
				<el-form-item label="确认新密码:">
					<el-input v-model="password.repassword" type="password" placeholder="请重新输入新密码"></el-input>
				</el-form-item>
			</el-form>
		</base-drawer>
	</div>
</template>
<script>
	import {
		ResetPwd
	} from "@/api/company";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	export default {
		props: {},
		data() {
			return {
				userinfo: "",
				passwordedit: false,
				password: {
					oldpassword: "",
					password: "",
					repassword: "",
				},
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
		},
		watch: {},
		mounted() {},
		methods: {
			//密码修改
			pwdEdit() {
				var _this = this;
				if (!_this.password.oldpassword) {
					_this.$message.warning("请输入旧密码！");
					return;
				}
				if (!_this.password.password) {
					_this.$message.warning("请输入新密码！");
					return;
				}
				if (!_this.password.repassword) {
					_this.$message.warning("请输入确认密码");
					return;
				}
				if (_this.password.password != _this.password.repassword) {
					_this.$message.warning("两次密码输入不一致");
					return;
				}
				var par = {
					id: this.userinfo.coM_ID,
					oldPwd: _this.password.oldpassword,
					newPwd: _this.password.password,
				};
				ResetPwd(par).then((res) => {
					if (res.success) {
						_this.$message.success("密码修改成功!");
						_this.passwordedit = false;
						_this.password = {
							oldpassword: "",
							password: "",
							repassword: "",
						};
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			loingout() {
				var _this = this;
				_this
					.$confirm("确定退出吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						localStorage.removeItem("token");
						localStorage.removeItem("userinfo");
						_this.resolvewin("/login", {
							type: 2
						}, 1);
					})
					.catch(() => {});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.Ctitle {
		width: 100%;
		padding: 15px 20px;
		box-sizing: border-box;
		background: white;
		font-size: 16px;
		color: #333;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.saveBox {
		width: 100%;
		background: white;
		padding: 0 25px 40px;
		box-sizing: border-box;

		.saveLine {
			height: 70px;
			border-bottom: 1px solid #f8f8f8;
			box-sizing: border-box;

			.saveIcon {
				width: 26px;
				height: 26px;
				margin-right: 20px;

				img {
					width: 100%;
				}
			}

			.saveTitle {
				width: 130px;
				color: #333;
			}

			.saveCont {
				width: 625px;
				color: #333;
			}

			.already {
				color: #fb6703;
			}

			.saveEdit {
				color: #276bf2;
				cursor: pointer;
			}
		}

		.saveBTN {
			width: 260px;
			height: 35px;
			line-height: 35px;
			text-align: center;
			color: #276bf2;
			border: 1px solid #276bf2;
			box-sizing: border-box;
			margin: 100px auto 0;
			cursor: pointer;
		}
	}
</style>
