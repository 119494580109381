<template>
	<div>
		<div class="Ctitle">招聘会管理</div>
		<div class="recommend">
			<div class="recTab flex2">
				<div class="flex">
					<div :class="['recTabItem', ISClick ? 'itemClick' : '']" @click="ISClickChange()">
						报名中
					</div>
					<div :class="['recTabItem', ISClick ? '' : 'itemClick']" @click="ISClickChange()">
						我已经报名
					</div>
				</div>
				<div class="job-search">
					<div class="search-content flex">
						<div class="dropdown flex">
							<div class="tit">招聘会类型</div>
							<el-dropdown @command="handleCommand">
								<span class="el-dropdown-link">
									{{POSINAME}}<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="">不限</el-dropdown-item>
									<el-dropdown-item command="0">普通</el-dropdown-item>
									<el-dropdown-item command="1">大型</el-dropdown-item>
									<el-dropdown-item command="2">赴外</el-dropdown-item>
									<el-dropdown-item command="3">直播</el-dropdown-item>
									<el-dropdown-item command="99">专场</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="dropdown flex">
							<div class="tit">招聘会年份</div>
							<el-dropdown @command="handleCommand1">
								<span class="el-dropdown-link">
									{{field2==''?"全部":field2}}<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="">全部</el-dropdown-item>
									<el-dropdown-item command="2022">2022</el-dropdown-item>
									<el-dropdown-item command="2021">2021</el-dropdown-item>
									<el-dropdown-item command="2020">2020</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="activity-contain">
			<div class="activity-cell">
				<div class="activity-item" v-for="(item, i) in activitydata" :key="i" @click="fairinfoChange(item.id)">
					<div class="activity-img">
						<img v-if="item.cZbLogo" :src="defimgurl + item.cZbLogo" alt="" />
						<img v-else :src="require('@/assets/img/activity-1.jpg')" />
					</div>
					<div class="activity-body">
						<div class="activity-name han">
							<b>【{{ item.cZhuti }}】</b>
							{{ item.cZph ? item.cZph : "无" }}
						</div>
						<div class="activity-state">
							<span class="label">{{ zphtype(item.cLeixin) }}</span>
							<span class="gyx" v-if="item.bIsZbzp">直播</span>
							<span class="label" v-if="item.cZphlb == '公益性'">公益性</span>
						</div>
						<div class="activity-data">
							活动时间：{{ item.cXianl }}
						</div>
						<div class="activity-address">
							活动地址：{{ item.cAddress ? item.cAddress : "无" }}
						</div>
					</div>
					<div class="activity-btn" v-if="ISClick">
						<el-button type="primary">点击报名</el-button>
					</div>
				</div>
			</div>
		</div>
		<el-empty style="background: #fff" v-if="activitydata.length < 1" :image-size="200"></el-empty>
		<div class="page" v-if="activitydata.length > 0 && total > 10">
			<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="pageNumber"
				:page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	import {
		jobfairprev,
		jobfaired
	} from "@/api/company";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	export default {
		props: {},
		data() {
			return {
				defimgurl: global.baseimgurl,
				userinfo: "",
				ISClick: true,
				activitydata: "",
				pageNumber: 1,
				pageSize: 10,
				total: 0,
				searchKey: '',
				POSINAME: '不限',
				field1: '',
				field2: '',
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
		},
		watch: {},
		mounted() {
			this.jobfairprev();
		},
		methods: {
			handleCommand(e) {
				this.field1 = e;
				this.POSINAME = this.zphtype(e);
				if (this.ISClick) {
					this.jobfairprev();
				} else {
					this.jobfaired();
				}
			},
			handleCommand1(e) {
				this.field2 = e;
				if (this.ISClick) {
					this.jobfairprev();
				} else {
					this.jobfaired();
				}
			},
			ISClickChange() {
				this.ISClick = !this.ISClick;
				this.PageSize = 10;
				this.PageNumber = 1;
				this.activitydata = [];
				if (this.ISClick) {
					this.jobfairprev();
				} else {
					this.jobfaired();
				}
			},
			//招聘会预告
			jobfairprev() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					field1: _this.field1,
					field2: _this.field2,
					types: '1',
					showLoadType: ".resume",
				};
				jobfairprev(par).then((res) => {
					if (res.success) {
						_this.total = res.data.total;
						_this.activitydata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//已参加招聘会
			jobfaired() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					field1: _this.field1,
					field2: _this.field2,
					showLoadType: ".resume",
				};
				jobfaired(par).then((res) => {
					if (res.success) {
						_this.total = res.data.total;
						_this.activitydata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},

			//当前显示数选取
			SizeChange(val) {
				this.PageSize = val;
				this.PageNumber = 1;
				if (this.ISClick) {
					this.jobfairprev();
				} else {
					this.jobfaired();
				}

				this.goPost();
			},
			//当前页选取
			CurrentChange(val) {
				this.PageNumber = val;
				if (this.ISClick) {
					this.jobfairprev();
				} else {
					this.jobfaired();
				}
				this.goPost();
			},
			zphtype(i) {
				switch (i) {
					case 0:
					case "0":
						return "普通招聘会";
						break;
					case 1:
					case "1":
						return "大型招聘会";
						break;
					case 2:
					case "2":
						return "赴外招聘会";
						break;
					case 3:
					case "3":
						return "直播招聘会";
						break;
					case 99:
					case "99":
						return "专场招聘会";
						break;
					default:
						return "不限";
						break;
				}
			},
			fairinfoChange(id) {
				this.resolvewin("/jobfairdetail", {
					id: id,
					gotoType: 1
				}, 2);
			},
			goPost() {
				let distance =
					document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
				let step = distance / 30; //每步的距离
				(function jump() {
					if (distance > 0) {
						distance -= step;
						window.scrollTo(0, distance);
						setTimeout(jump, 10);
					}
				})();
			},
		},
	};
</script>
<style lang="scss" scoped>
	.Ctitle {
		width: 100%;
		padding: 15px 20px;
		box-sizing: border-box;
		background: white;
		font-size: 16px;
		color: #333;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.recommend {
		width: 100%;


		.recTab {
			width: 100%;
			height: 60px;
			background: white;
			padding: 0 25px;
			box-sizing: border-box;
			color: #333;
			border-bottom: 1px solid #eee;

			.recTabItem {
				height: 100%;
				line-height: 60px;
				margin-right: 50px;
				color: #898989;
			}

			.itemClick {
				box-sizing: border-box;
				color: #101010;
				font-size: 16px;
			}

			.recTabItem:hover {
				cursor: pointer;
			}
		}

		.search {
			flex: 1;
			width: 30%;

			.el-form {
				.el-form-item ::v-deep {
					margin-bottom: 0;

					.el-form-item__content {
						display: flex;
						align-items: stretch;

						.search-input {
							display: flex;
							border: 1px solid #999;

							.el-input {
								.el-input__inner {
									border: none;
									width: 270px;
								}
							}

							.el-select {
								.el-input__inner {
									border: none;
									width: 300px;
								}

								.el-input::before {
									content: "";
									position: absolute;
									width: 1px;
									height: 18px;
									left: 0px;
									top: 11px;
									background: #999;
								}
							}
						}

						.search-btn {
							.el-button {
								background: #66b1ff;
								border-radius: 0;
								height: 100%;
								padding: 12px 15px;
							}
						}
					}
				}
			}
		}

		.job-search {
			position: relative;

			.search-content {
				margin: 0px auto;
				padding: 15px 20px;
				justify-content: end;

				.dropdown {
					line-height: 30px;
					margin-right: 50px;

					.tit {
						color: #101010;
						margin-right: 15px;
					}
				}

				.position-tab-title {
					width: 98%;
					background: #fff;
					margin-bottom: 12px;
					padding: 20px 0 1px 0;

					.position-tab-a {
						display: flex;
						font-size: 14px;
						margin-bottom: 20px;

						.title {
							font-weight: bold;
							color: #333;
							padding: 5px 10px;
							min-width: 90px;
						}

						.info {
							span {
								margin: 0 20px;
							}

							.info-a {
								cursor: pointer;
								padding: 5px 10px;
								margin-right: 10px;
							}

							.info-Select {
								color: #fff;
								background-color: #2778f8;
								border-radius: 4px;
							}
						}

						.el-dropdown-link {
							cursor: pointer;
						}
					}
				}
			}
		}

		.resume {
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			background: white;
			margin-bottom: 10px;

			.resumeLeft {
				width: 88%;

				.left {
					width: 60%;
					display: flex;
					border-right: 1px solid #edf1f5;

					.pic {
						width: 85px;
						height: 85px;
						border-radius: 50%;
						overflow: hidden;
						margin-right: 20px;

						img {
							width: 100%;
						}
					}

					.personal {
						display: flex;
						flex-direction: column;
						justify-content: center;

						.name {
							color: #276bf2;
							font-size: 16px;
							margin-bottom: 15px;

							i {
								color: rgb(220, 0, 29);
								margin-left: 10px;
							}

							.brisk {
								font-size: 14px;
								color: #666;
								margin-left: 25px;
							}
						}

						.salary {
							margin-right: 20px;
							color: #ff7630;
							font-size: 16px;
						}

						.resumeFactor {
							margin-right: 10px;
						}
					}
				}

				.right {
					width: 40%;
					margin-left: 20px;

					.state {
						color: #666;
						font-size: 14px;

						.top {
							margin-bottom: 15px;
						}
					}
				}
			}

			.resumeRight {
				width: 12%;
				font-size: 14px;

				.talk {
					color: #fb6703;
					margin-bottom: 10px;

					img {
						width: 22px;
						height: 22px;
						margin-right: 5px;
					}
				}

				.collect {
					color: #276bf2;

					i {
						margin-right: 5px;
					}

					.iconoff {
						font-size: 24px;
					}

					.iconon {
						font-size: 28px;
					}
				}

				.talk:hover,
				.collect:hover {
					cursor: pointer;
				}
			}
		}

		.resume:hover {
			box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
		}
	}

	.jobFair {
		width: 100%;
		padding: 20px 30px;
		box-sizing: border-box;
		background: white;
		margin-bottom: 10px;

		.mainTitle {
			font-size: 14px;
			color: #333;

			.main {
				font-size: 28px;
				margin-bottom: 10px;
				display: flex;
				align-items: center;
			}
		}

		.release {
			width: 120px;
			height: 35px;
			font-size: 14px;
			background: #276bf2;
			color: white;
			border-radius: 4px;
			line-height: 35px;
			text-align: center;
			cursor: pointer;
			margin-left: 10px;
		}

		.releases {
			color: #999999;
			text-align: center;
			cursor: pointer;

			img {
				width: 25px;
				height: 25px;
				margin-right: 5px;
			}
		}
	}

	//  招聘会
	.activity-contain {
		width: 100%;
		box-sizing: border-box;

		margin-bottom: 15px;

		.title {
			font-size: 18px;
			color: #000;
			padding: 15px 0 0 15px;
		}

		.activity-cell {
			.activity-item {
				position: relative;
				display: flex;
				background: white;
				align-items: center;
				padding: 15px;
				margin-bottom: 10px;
				cursor: pointer;
				border-top: 1px solid #eee;

				.activity-img {
					width: 200px;
					height: 110px;
					border-radius: 4px;
					display: flex;
					overflow: hidden;
					align-items: center;
					justify-content: center;

					img {
						width: 100%;
					}
				}

				.activity-body {
					flex: 1;
					width: 100%;
					text-align: left;
					padding-left: 20px;
					position: relative;

					.activity-name {
						color: #000;
						font-size: 16px;
						font-weight: bold;
						width: 80%;
						margin-bottom: 10px;

						b {
							color: #ff5722;
						}
					}

					.activity-state {
						margin-bottom: 10px;
						margin-left: 10px;

						.gyx {
							padding: 4px 10px;
							border-radius: 4px;
							background: #ff7630;
							color: white;
							font-size: 14px;
							margin-right: 10px;
						}

						.label {
							padding: 4px 10px;
							border-radius: 4px;
							background: #409eff;
							color: white;
							font-size: 14px;
							margin-right: 10px;
						}
					}

					.activity-data {
						font-size: 14px;
						color: #787878;
						margin-bottom: 5px;
					}

					.activity-address {
						font-size: 14px;
						color: #787878;
					}
				}

				.activity-btn {
					position: absolute;
					top: 15px;
					right: 15px;
				}
			}

			.activity-item:first-child {
				border-top: none;
			}
		}
	}

	.han {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.page {
		width: 100%;
		background: white;
		height: 80px;
		line-height: 80px;

		.el-pagination {
			width: 100%;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			::v-deep.el-pager li {
				vertical-align: middle;
				color: #666;
				margin: 0 10px;
				font-weight: normal;
			}

			::v-deep.el-pager li.active {
				color: #fff;
				background: #276bf2;
				border-radius: 4px;
			}

			::v-deep.el-pager li:hover {
				color: #276bf2;
			}
		}
	}
</style>
