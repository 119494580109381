<template>
	<div>
		<!-- 内容 -->
		<div class="contain flex">
			<div class="left">
				<!-- 导航 -->
				<div class="nav">
					<div @click="navChange(item.index)" :class="['item', item.index == navindex ? 'itemClick' : '']"
						v-for="(item, index) in nav" :key="index" @mouseenter="naventer(item.index)"
						@mouseleave="navleave()">
						<div class="name">
							<img :src="
                  item.index == navindex || item.index == navindexHover
                    ? item.iconc
                    : item.icon
                " />{{ item.name }}
						</div>
						<div><i class="el-icon-arrow-right"></i></div>
					</div>
				</div>
			</div>
			<div class="right">
				<!-- 企业中心 -->
				<div v-if="navindex == 0">
					<div class="QYcenter">
						<!-- 公司信息 -->
						<div class="company flex">
							<div class="companyLeft flex">
								<div class="logo">
									<img :src="setcompanylogo(userinfo.coM_LOGO)" alt="" />
								</div>
								<div class="companyIntro">
									<div class="companyName toExamine">{{ userinfo.coM_NAME }}
										<div class="examine red" v-if="userinfo.coM_SH == 0"><img
												src="@/assets/img/examine.png" alt="">账号未审核</div>
										<div class="examine red" v-if="ispaybycomiddata == 2">您有新的网络招聘业务未付款，<a
												class="blue" @click="YWBLDialog = true">点击前往办理</a></div>
										<div class="examine red" v-else-if="ispaybycomiddata != 0">您当前无有效的网络招聘，<a
												class="blue" @click="YWBLDialog = true">点击前往办理</a></div>
									</div>
									<div class="factor flex1">
										<div class="factorList">
											<img
												src="@/assets/img/company/icon1-1.png"><span>{{ userinfo.hangye }}</span>
										</div>
										<div class="factorList">
											<img
												src="@/assets/img/company/icon1-2.png"><span>{{ userinfo.leixing }}</span>
										</div>
										<div class="factorList">
											<img
												src="@/assets/img/company/icon1-3.png"><span>{{ userinfo.guimo }}</span>
										</div>
									</div>
								</div>
								<div class="Tips flex">
									<div class="TipsM">
										<span>简历下载率</span>
										<b>{{servernum.jl_num}}%</b>
									</div>
									<div class="TipsM">
										<span>岗位发布率</span>
										<b>{{servernum.zp_num}}%</b>
									</div>
									<div class="TipsM">
										<span>综合利用率</span>
										<b>{{servernum.num}}%</b>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="box">
						<div class="box-head">
							<div class="title">快捷功能</div>
						</div>
						<div class="btnlist">
							<div class="btns"
								@click="ispaybycomiddata == 0? (postDrawer = !postDrawer): (YWBLDialog = true)">
								<img src="@/assets/img/company/1.png" alt="Alternate Text">
								<div>发布岗位</div>
							</div>
							<div class="btns" @click="navChange(1)">
								<img src="@/assets/img/company/2.png" alt="Alternate Text">
								<div>查收简历</div>
							</div>
							<!-- <div class="btns">
								<img src="@/assets/img/company/3.png" alt="Alternate Text">
								<div>入职通知</div>
							</div> -->
							<div class="btns" @click="navChange(5)">
								<img src="@/assets/img/company/4.png" alt="Alternate Text">
								<div>招聘会</div>
							</div>
							<div class="btns" @click="gotoPost(1)">
								<img src="@/assets/img/company/7.png" alt="Alternate Text">
								<div>招聘中</div>
							</div>
							<div class="btns" @click="gotoPost(0)">
								<img src="@/assets/img/company/8.png" alt="Alternate Text">
								<div>所有岗位</div>
							</div>
							<div class="btns" @click="navChange(9)">
								<img src="@/assets/img/company/9.png" alt="Alternate Text">
								<div>业务办理</div>
							</div>
						</div>
					</div>
					<div class="message">
						<div class="flex1">
							<img class="message-i" src="@/assets/img/message.png" alt="" />
							{{messagecount? "您有" + messagecount + "条新消息提醒，前往小程序查看": "暂无最新消息，可前往小程序同求职人员进行一对一沟通"}}
						</div>
						<div class="message-right" v-if="messageList.length>0">
							<img @click="getmessagelist();Mdialog = true" class="message-r" slot="reference"
								src="@/assets/img/sm.png" alt="" />
							<div class="badge" v-if="messagecount">
								{{ messagecount > 99 ? 99 : messagecount }}
							</div>
						</div>
					</div>

					<div class="box business">
						<div class="box-head business-top">
							<div class="title">业务管理</div>
						</div>
						<div class="box-body flex">
							<div class="business-left">
								<template>
									<el-table :data="tableData" style="width: 100%" :show-header="false"
										@selection-change="handleSelectionChange">
										<el-table-column type="selection" :selectable="selectable">
										</el-table-column>
										<el-table-column prop="service_Name" label="业务名称" width="320">
											<template slot-scope="scope">
												<span class="">{{scope.row.service_Name}}</span><br>
												<span
													class="hui">{{scope.row.service_Cs!='' ? scope.row.service_Cs+"," : ""}}</span>
											</template>
										</el-table-column>
										<el-table-column label="业务">
											<template slot-scope="scope">
												<a class="red" @click="serviceClick(scope.row, true)"
													v-if="!scope.row.isPay && !scope.row.payVucher">
													<i class="el-icon-timer"></i> “ 未缴费”点击前往缴费
												</a>
												<span class="green"
													v-else-if="utils.nowInDateBetwen(0, scope.row.dend) != 2">
													<i class="el-icon-timer"></i> 招聘中
												</span>
												<span class="orgin" v-else>
													<i class="el-icon-timer"></i> 已到期</span><br>
												<span
													class="hui">有效期至{{scope.row.dend ? stringToDates(scope.row.dend) : ""}}</span>
											</template>
										</el-table-column>
										<el-table-column label="操作" width="120">
											<template slot-scope="scope">
												<div class="business-btn">
													<div @click="serviceClick(scope.row, false)"
														class="yellow business-t" v-if="scope.row.isPay">续费
													</div>
													<div class="blue business-t" @click="FPtablechange(scope.row.id)"
														v-if="!scope.row.isTicket && scope.row.isPay">发票
													</div>
												</div>
											</template>
										</el-table-column>
									</el-table>
								</template>
							</div>
							<!-- <div class="business-right">
								<div class="botton-li">
									<div class="botton btn-a" v-if="ispaybycomiddata != 1" @click="ispay = !ispay">
										续费
									</div>
									<div class="botton btn-b" @click="isFPshow = !isFPshow">
										索要发票
									</div>
								</div>
							</div> -->
						</div>
					</div>

					<!-- 人才推荐 -->
					<div class="recommend">
						<div class="recTab flex1">
							<div :class="['recTabItem', ISClick ? 'itemClick' : '']" @click="ISClickChange()">
								最近收到的简历
							</div>
							<!-- <div :class="['recTabItem', ISClick ? '' : 'itemClick']" @click="ISClickChange()">
									最新活跃
								</div> -->
							<!-- <div class="recTabItem" @click="(navindex = 5), (PerSendClick = 2), goPost()">
								<el-badge :value="zphjobs" class="badegitem">
									<span style="padding-right: 5px">招聘简历</span>
								</el-badge>
							</div> -->
							<!-- <div class="recTabItem" style="cursor: auto;flex: 1;margin-right: 0;text-align: right;">
								（已下载简历数/可下载简历数：<span
									style="font-size: 15px;color: #276bf2;font-weight: bold;">{{userinfo.coM_JL_SC==null?0:userinfo.coM_JL_SC}}</span><span
									style="font-weight: bold;font-size: 15px;"> /
									{{(userinfo.coM_JL_NUM-(userinfo.coM_JL_SC==null?0:userinfo.coM_JL_SC))}}</span>）
							</div> -->
						</div>
						<!-- 人才推荐 -->
						<div>
							<div class="resume flex2" v-for="(resumeItem, resume) in PerJobList" :key="resume">
								<div class="resumeLeft flexc">
									<div class="left" @click="isRshow=!isRshow;uid=resumeItem.peR_ID;isCall=1">
										<div class="pic">
											<img :src="
					          resumeItem.peR_IMG_PATH
					            ? setuserlogo(resumeItem.peR_IMG_PATH)
					            : require('@/assets/img/tx.png')
					        " alt="" />
										</div>
										<div class="personal">
											<div class="name flex1">
												<div>{{ resumeItem.peR_NAME }}</div>
												<i v-if="resumeItem.peR_GENDER == '女'" class="el-icon-female nv"></i>
												<i v-else class="el-icon-male nan"></i>
												<div class="brisk">{{ resumeItem.hy }}</div>
											</div>
											<div class="flex1">
												<div class="salary">
													{{
					            resumeItem.hope_xinzi
					              ? resumeItem.hope_xinzi
					              : "面议"
					          }}
												</div>
												<div class="flex1">
													<div class="resumeFactor">
														{{
					              resumeItem.hope_quxian
					                ? resumeItem.hope_quxian
					                : ""
					            }}
													</div>
													<div class="resumeFactor" v-if="resumeItem.hope_quxian">
														<span>·</span>
													</div>
													<div class="resumeFactor">
														{{
					              resumeItem.peR_WORK_YEARS
					                ? resumeItem.peR_WORK_YEARS + "年"
					                : ""
					            }}
													</div>
													<div class="resumeFactor" v-if="resumeItem.peR_WORK_YEARS">
														<span>·</span>
													</div>
													<div class="resumeFactor">
														{{ resumeItem.xueli ? resumeItem.xueli : "" }}
													</div>
													<div class="resumeFactor" v-if="resumeItem.xueli">
														<span>·</span>
													</div>
													<div class="resumeFactor">
														{{
					              resumeItem.peR_WORK_NATURE
					                ? resumeItem.peR_WORK_NATURE
					                : ""
					            }}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="right">
										<div class="state" v-if="ISClick">
											<div class="top">
												投递岗位： {{ resumeItem.oF_POSI_NAME }}
											</div>
											<div class="bottom">
												投递时间：
												{{ resumeItem.cSendDate ? resumeItem.cSendDate : "" }}
											</div>
										</div>
										<div class="state" v-else>
											<div class="top">
												求职状态：{{
					          resumeItem.peR_PER_STATUS
					            ? resumeItem.peR_PER_STATUS
					            : "考虑就业机会"
					        }}
											</div>
											<div class="bottom">
												到岗时间：{{
					          resumeItem.peR_PER_DGSJ
					            ? resumeItem.peR_PER_DGSJ
					            : "暂未确定"
					        }}
											</div>
											<div class="bottom"></div>
										</div>
									</div>
								</div>
								<div class="resumeRight">
									<div class="talk flexc" @click="isRshow=!isRshow;uid=resumeItem.peR_ID;isCall=1">
										<img :src="require('@/assets/img/c16.png')" alt="" />
										查看简历
									</div>
									<!-- <div class="talk flexc hui" v-else>
					    <img :src="require('@/assets/img/c26.png')" alt="" />
					    简历已隐藏
					  </div> -->
									<div class="collect flexc" v-if="resumeItem.isFav == 0"
										@click="favuser(resumeItem.peR_ID)">
										<i class="el-icon-star-off iconoff"></i>
										收藏
									</div>
									<div class="collect flexc" @click="cancelfavuser(resumeItem.peR_ID)" v-else>
										<i class="el-icon-star-on iconon"></i>
										已收藏
									</div>
								</div>
							</div>
							<div style="background: #fff;text-align: center;padding: 25px;" v-if="PerJobList.length < 1"
								:image-size="200" description="暂无最新数据">暂无最新数据</div>
						</div>
					</div>
				</div>
				<!-- 岗位管理 -->

				<PostList :postDrawer="postDrawer" @YWBLbind="YWBLbind" :ywstartdate='ywstartdate' :navindex="navindex"
					@refreshcompany="companydetail"></PostList>
				<!-- 简历管理 -->
				<PerSend v-if="navindex == 1" @YWBLbind="YWBLbind" :Click="PerSendClick"></PerSend>
				<!-- 人才库 -->
				<PerSearch v-if="navindex == 8"></PerSearch>
				<!-- 公司信息 -->
				<InfoEdit v-if="navindex == 6" @infoedit="infoedit"></InfoEdit>
				<!-- 招聘会管理 -->
				<JobFairList v-if="navindex == 5"></JobFairList>

				<div class="page" v-if="navindex == 0 && PerJobList.length > 0 && total > 10">
					<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="pageNumber"
						:page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>

				<SecurityCenter v-if="navindex == 7"></SecurityCenter>

				<YWList :ispay="ispay" :isFPshow="isFPshow" :navindex="navindex" :ywListId='ywListId'
					@YWBLbind="YWBLbind"></YWList>

			</div>
		</div>

		<resumedetail :isRshow="isRshow" :isCall="isCall" :uid="uid"></resumedetail>
		<el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%">
			<div class="popoverinfo">
				<img class="popoverinfo-img" src="@/assets/img/yydy.png" alt="" />
				<div class="popoverinfo-span">
					微信扫一扫，与求职人员在线进行一对一交流
				</div>
				<div class="popoverinfo-span">
					初次登录需绑定，<span @click="downfile">
						点我查看如何绑定 <i class="el-icon-arrow-right"></i></span>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="footer-btn" type="primary" @click="downfile">操作手册</el-button>
				<el-button class="footer-btn" @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>

		<el-dialog width="860px" title="兰溪人才网-消息" :visible.sync="Mdialog" :direction="direction" class="messagedetail">
			<div class="flex">
				<div class="message-left innerbox">
					<div class="message-list ">
						<div class="item flex" v-for="(item, index) in messageList" :key="index"
							@click="getmessageinfo(item)">
							<div class="mc">
								<a @click="gethidemessageinfo(item)"><i class="el-icon-delete red"></i></a>
							</div>
							<div class="Logo"><img :src="setuserlogo(item.Logo)" /></div>
							<div style="width: 75%;">
								<div class="flex2">
									<div class="name">
										<span>{{item.name}}</span>
									</div>
									<div class="time">
										{{item.time}}
									</div>
								</div>
								<div class="flex2">
									<div class="info">{{item.memu}}</div>
									<div class="badge" v-if="item.read>0">
										<div class="badge-i">{{item.read}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="message-right">
					<div class="message-box">
						<div class="message-title">
							{{dialogTitle}}
						</div>
						<div class="message-detail innerbox">
							<div class="message-scroll">
								<div v-for="(item, index) in messageInfo" :key="index">
									<!-- 岗位 -->
									<div class="postList" v-if="item.typeId == 1||item.typeId == 6"
										v-for="(items, indexs) in item.memu" :key="indexs">
										<div class="md-company">
											<div class="flex">
												<div class="flex1">
													<div class="name">
														{{item.typeId == 6?items.Zwmc?items.Zwmc:"": items.OF_POSI_NAME?items.OF_POSI_NAME:'' }}
													</div>
													<!-- <image src="https://www.zjykrc.com/img/ji.png" alt="" /> -->
												</div>
												<div class="salarr" v-if='item.typeId == 1'>
													{{item.OF_SALARY_MY == 1 || item.OF_SALARY_MY == null ? "面议" : item.SALARY}}
												</div>
												<div class="salarr" v-if='item.typeId == 6'>
													{{items.cMy == 1 || items.cMy == null? "面议": numK(items.cdyb,items.cdye)}}
												</div>
											</div>
											<!-- <div class="postInfo">{{ items.OF_POSI_DESC }}</div> -->
											<div class="flex1" v-if='item.typeId == 1'>
												<div class="factor" v-if='items.OF_POSI_NATURE'>
													{{ items.OF_POSI_NATURE }}
												</div>
												<div class="factor" v-if='items.OF_EDU_REQ'>
													{{items.OF_EDU_REQ == "不限"? "学历" + items.OF_EDU_REQ : items.OF_EDU_REQ}}
												</div>
												<div class="factor" v-if='items.OF_WORKAREA'>
													{{ items.OF_WORKAREA ? items.OF_WORKAREA : "城市未知" }}
												</div>
											</div>
											<div class="flex1" v-if='item.typeId == 6'>
											</div>
											<div class="flex2">
												<div :class="['portrait', 'qycolor' + items.Num]">
													{{ items.LogoName }}
												</div>
												<div class="tel">
													<div>{{ items.COM_NAME }}</div>
													<div>{{ items.COM_address }}</div>
												</div>
											</div>
										</div>
									</div>
									<!-- time -->
									<!-- <div class="time" v-if="item.typeId == 6">{{utils.timeFormat(item.createTime)}}</div> -->
									<!-- 弹窗 
									<div class="popup" v-if="item.typeId==5&&item.idType!=idType">
										<div class="flex1">
											<div class="pic">
												<image src="https://www.zjykrc.com/img/hsjsd.png" alt="" />
											</div>
											<div style="width: 190px">对方请求与您交换联系方式，您是否同意？</div>
										</div>
										<div class="btn flex" v-if="item.isRead==0">
											<div class="btnlist" @click="GetPhone(item.id,2)">拒绝</div>
											<div class="btnlist" @click="GetPhone(item.id,1)">同意</div>
										</div>
										<div class="btn flex" v-if="item.IsRead==1">您已同意
										</div>
										<div class="btn flex" v-if="item.IsRead==2">您已拒绝</div>
									</div>
									<div class="popup" v-if="item.typeId==5&&item.idType==idType">
										<div class="flex1">
											<div class="pic">
												<image src="https://www.zjykrc.com/img/hsjsd.png" alt="" />
											</div>
											<div style="width: 190px">您请求与对方交换联系方式，等待是否同意？</div>
										</div>
										<div class="btn flex" v-if="item.isRead==1">对方同意
										</div>
										<div class="btn flex" v-if="item.isRead==2">对方拒绝</div>
									</div>
									<div class="popup" v-if="item.typeId==3&&item.idType!=idType">
										<div class="flex1">
											<div class="pic">
												<image src="https://www.zjykrc.com/img/hsjsd.png" alt="" />
											</div>
											<div style="width: 190px">对方请求与您发起视频，您是否同意？</div>
										</div>
										<div class="btn flex" v-if="item.isRead==0">
											<div class="btnlist" @click="GetCall(item.Id,2)">拒绝</div>
											<div class="btnlist" @click="GetCall(item.Id,1)">同意</div>
										</div>
										<div class="btn flex" v-if="item.isRead==1">您已同意</div>
										<div class="btn flex" v-if="item.isRead==2">您已拒绝</div>
										<div class="btn flex" v-if="item.isRead==3">视频已结束</div>
										<div class="btn flex" v-if="item.isRead==4">视频已超时</div>
									</div>
									<div class="popup" v-if="item.typeId == 3 && item.idType == idType">
										<div class="flex1">
											<div class="pic">
												<image src="https://www.zjykrc.com/img/hsjsd.png" alt="" />
											</div>
											<div style="width: 190px">
												您请求与对方发起视频，等待是否同意？
											</div>
										</div>
										<div class="btn flex" v-if="item.isRead == 1">
											<div class="btnlist" @click="GetCall(item.id,3)">进入</div>
											对方同意
										</div>
										<div class="btn flex" v-if="item.isRead == 2">对方拒绝</div>
										<div class="btn flex" v-if="item.isRead == 3">视频已结束</div>
										<div class="btn flex" v-if="item.isRead == 4">视频已超时</div>
									</div>-->
									<!-- 对话框 -->
									<div class="message-hr dialog" v-if="item.typeId == 0 && item.idType!=idType">
										<div :class="['message-hr-img', 'portrait', 'qycolor' + item.num]">
											<img :src="setuserlogo(item.Logo)" />
										</div>
										<div class="message-hr-news dialogCont">
											{{ item.memu }}
										</div>
									</div>
									<div class="message-me dialog" v-if="item.typeId == 0 && item.idType == idType">
										<div class="message-me-news dialogCont">
											{{ item.memu }}
										</div>
										<div :class="['message-me-img', 'portrait', 'qycolor' + item.num]">
											<img :src="setcompanylogo(item.Logo)" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<!--输入框-->
						<div class="writing flex">
							<input type="text" name="Memu" v-model="Memu" placeholder="请输入..." />
							<el-button class="mf-icon" @click="SendMemu()">发送</el-button>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="srcList" />
		<el-dialog title="温馨提示" :visible.sync="YWBLDialog" width="25%" center>
			<div style="width: 100%; text-align: center">
				<span v-if="ispaybycomiddata == 2">您当前未办理网络招聘业务，请前往办理?</span>
				<span v-if="ispaybycomiddata == 3">您当前办理的业务未完成支付，暂不允许该操作，请前往缴费?</span>
				<span v-if="ispaybycomiddata == 4">您当前办理的业务已过期，请前往重新办理?</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" class="footer-btn" @click="YWBLbind">确 定</el-button>
				<el-button class="footer-btn" @click="YWBLDialog = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import ElImageViewer from "element-ui/packages/image/src/image-viewer";
	import {
		rencaituijian,
		lastestact,
		resumefavreport,
		getOperationmanualbynews,
		favuser,
		posilistall,
		cancelfavuser,
		companydetail,
		GetPerJobByComId,
		IsReadMessage,
		CompanyServiceList,
		ispaybycomid,
		refreshcompanydate,
		getywstartdate,
		notreadzphjobs,
		servernum
	} from "@/api/company";
	import {
		getmessagelist,
		getmessageinfo,
		addmessageinfo,
		hidemessageinfo
	} from "@/api/home";
	import utils from "@/assets/js/utils";
	import globalconfig from "@/assets/js/globalconfig";
	import {
		regionData
	} from "element-china-area-data";
	import maps from "@/components/map";
	import {
		isnull,
		handleCofirm,
	} from "@/assets/js/index";
	import YWList from "./YWList.vue";
	import SecurityCenter from "./SecurityCenter.vue";
	import JobFairList from "./JobFairList.vue";
	import InfoEdit from "./InfoEdit.vue";
	import PerSend from "./PerSend.vue";
	import PerSearch from "./PerSearch.vue";
	import InfoData from "./InfoData.vue";
	import PostList from "./PostList.vue";
	import resumedetail from "../resume/resumedetail.vue";
	export default {
		components: {
			maps,
			ElImageViewer,
			SecurityCenter,
			JobFairList,
			InfoEdit,
			PerSend,
			InfoData,
			PostList,
			YWList,
			PerSearch,
			resumedetail
		},
		data() {
			return {
				PerSendClick: 0,
				YWBLDialog: false,
				dialogTable: false,
				defimgurl: globalconfig.baseimgurl,
				baseurl: globalconfig.baseurl,
				headers: {
					token: localStorage.getItem("token"),
				},
				userinfo: "",
				servernum: {
					jl_num: 0,
					zp_num: 0
				},
				zphjobs: 0,
				btn: false,
				editBtn2: true,
				btn2: false,
				editBtn3: true,
				btn3: false,
				edit: false,
				pageNumber: 1,
				pageSize: 10,
				total: 0,
				ISClick: true,
				navindex: 0,
				navindexHover: "",
				Mdialog: false,
				drawer: false,
				direction: "rtl",
				idbr: true,
				postList: [],
				nav: [{
						index: 0,
						name: "企业中心",
						icon: require("@/assets/img/company/nav0.png"),
						iconc: require("@/assets/img/company/nav0h.png"),
					},
					{
						index: 3,
						name: "招聘岗位管理",
						icon: require("@/assets/img/company/nav1.png"),
						iconc: require("@/assets/img/company/nav1h.png"),
					},
					{
						index: 8,
						name: "搜索简历",
						icon: require("@/assets/img/company/nav2.png"),
						iconc: require("@/assets/img/company/nav2h.png"),
					},
					{
						index: 1,
						name: "简历管理",
						icon: require("@/assets/img/company/nav3.png"),
						iconc: require("@/assets/img/company/nav3h.png"),
					},
					{
						index: 5,
						name: "招聘会",
						icon: require("@/assets/img/company/nav4.png"),
						iconc: require("@/assets/img/company/nav4h.png"),
					},
					{
						index: 6,
						name: "企业信息",
						icon: require("@/assets/img/company/nav5.png"),
						iconc: require("@/assets/img/company/nav5h.png"),
					},
					{
						index: 9,
						name: "业务管理",
						icon: require("@/assets/img/company/nav6.png"),
						iconc: require("@/assets/img/company/nav6h.png"),
					},
					{
						index: 7,
						name: "安全中心",
						icon: require("@/assets/img/company/nav7.png"),
						iconc: require("@/assets/img/company/nav7h.png"),
					},
				],
				rid: 0,
				messageList: [],
				messageInfo: [],
				dialogTitle: "消息",
				pageScrollTo: true,
				Memu: '',
				sxdata: [{
						name: "行业",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "证件类别",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "公司规模",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "公司性质",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "招聘类别",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "学历",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "薪资",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "行业",
						index: 0,
						list: [],
						data: ""
					},
				],
				postDrawer: false,
				adddata: "",
				editinfo: "",

				PerJobList: [],
				tableData: [],
				AlltableData: [],
				messagecount: 0,

				Service: false,

				JFdata: {
					COM_ID: "",
					payVucher: "",
					cQj: "",
					YW_TYPE: "",
					DBGN: "",
					DEND: "",
					FEE: "",
					RFee: "",
				},
				isFPshow: false,
				ispay: false,
				ywListId: "",
				dialogVisible: false,

				srcList: [],
				showViewer: false,
				ispaybycomiddata: 0,
				ywstartdate: "",
				searchKey: "",
				searchCode: "",
				pickerOptions: {
					disabledDate: (time) => {
						return this.dealDisabledDate(time);
					},
				},
				idType: 1,
				isCall: 0,
				isRshow: false,
				uid: 0,
			};
		},
		created() {
			if (isnull(localStorage.getItem("userinfo"))) {
				localStorage.removeItem("token");
				localStorage.removeItem("userinfo");
				this.resolvewin("/login", {
					type: 2
				}, 1);
				return;
			}

			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			this.editinfo = JSON.parse(JSON.stringify(this.userinfo));
			this.companydetail();
			this.companyservernum();
			this.navindex = localStorage.getItem("navindex") ?
				parseInt(localStorage.getItem("navindex")) :
				0;
			this.urlpost();

			this.CompanyServiceList();
			this.ispaybycomid();
			this.getywstartdate();

			//this.getmessagelist();
			//this.IsReadMessage();
			this.timers = setInterval(() => {
				this.IsReadMessage();
				if (this.Mdialog)
					this.getmessagelist();
				if (this.rid > 0)
					this.getmessageroom();
			}, 3000);

			for (const i in this.sxdata) {
				this.posilistall(i);
			}
		},
		destroyed: function() {
			clearInterval(this.timers)
		},
		methods: {
			drawerChange(id, isCall) {
				this.uid = id;
				this.isCall = isCall;
				this.isRshow = !this.isRshow;
			},
			numK(mindata, maxdata) {
				var min = mindata ? mindata / 1000 : 0
				var max = maxdata ? maxdata / 1000 : 0
				if (min == 0 && max != 0) {
					return max + 'K'
				} else if (min != 0 && max == 0) {
					return min + 'K'
				} else if (min == 0 && max == 0) {
					return '面议'
				} else {
					return min + 'K-' + max + 'K'
				}
			},
			serviceClick(item, ispay = false) {
				this.ispay = !this.ispay;
			},
			FPtablechange(id) {
				this.isFPshow = !this.isFPshow;
				this.ywListId = "" + id;
			},
			YWBLbind() {
				this.navindex = 9;
				this.YWBLDialog = false;
				this.urlpost();
			},
			//消息
			IsReadMessage() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID, //80032, //
					types: 2,
				};
				IsReadMessage(par).then((res) => {
					if (res.success) {
						_this.messagecount = res.data.count;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//业务列表
			CompanyServiceList() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
				};
				CompanyServiceList(par).then((res) => {
					if (res.success) {
						_this.tableData = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			companyservernum() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
				};
				servernum(par).then((res) => {
					if (res.success) {
						_this.servernum = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			stringToDates(data) {
				return this.utils.stringToDate(data);
			},
			//表格全选
			handleSelectionChange(data) {
				var list = "";
				for (const i in data) {
					list = list + data[i].id;

					if (parseInt(i) + 1 < data.length) {
						list = list + ",";
					}
				}
				this.ywListId = list;
			},
			selectable(row, index) {
				if (row.isTicket == 1) {
					return false;
				} else {
					if (row.isPay) {
						return true;
					} else {
						return false;
					}
				}
			},

			companydetail() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
				};
				companydetail(par).then((res) => {
					if (res.success) {
						res.data.rows[0].userType = 2;
						localStorage.setItem("userinfo", JSON.stringify(res.data.rows[0]));
						this.$store.state.userinfo = res.data.rows[0];
						_this.userinfo = res.data.rows[0];
						_this.editinfo = res.data.rows[0];
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//推荐or活跃切换
			ISClickChange() {
				this.ISClick = !this.ISClick;
				this.PageSize = 10;
				this.PageNumber = 1;
				if (this.navindex == 0) {
					if (this.ISClick) {
						this.GetPerJobByComId();
					} else {
						this.lastestact();
					}
				}
			},

			//人才推荐
			rencaituijian() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					showLoadType: ".resume",
				};
				rencaituijian(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						});
						_this.total = res.data.total;
						_this.PerJobList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//最近活跃
			lastestact() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					showLoadType: ".resume",
				};
				lastestact(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						});
						_this.total = res.data.total;
						_this.PerJobList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//人才收藏
			favuser(id) {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					uid: id,
				};
				favuser(par).then((res) => {
					if (res.success) {
						if (this.navindex == 0) {
							if (this.ISClick) {
								this.GetPerJobByComId();
							} else {
								this.lastestact();
							}
						}
						_this.$message.success("简历已收藏!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//取消人才收藏
			cancelfavuser(id) {
				var _this = this;
				handleCofirm('确认取消收藏该简历吗？', 'warning', ).then(res => {
					var par = {
						id: this.userinfo.coM_ID,
						uid: id,
					};
					cancelfavuser(par).then((res) => {
						if (res.success) {
							if (this.navindex == 0) {
								if (this.ISClick) {
									this.GetPerJobByComId();
								} else {
									this.lastestact();
								}
							}
							_this.$message.success("已取消收藏!");
						} else {
							_this.$message.error(res.msg);
						}
					});
				}).catch(err => {
					//console.log('err', err) // cancel
				})
			},
			//是否可以发布岗位
			ispaybycomid() {
				var _this = this;
				ispaybycomid({
					id: this.userinfo.coM_ID
				}).then((res) => {
					_this.ispaybycomiddata = res.data;
					if (_this.ispaybycomiddata != 0) {
						_this.nav.splice(2, 1);
					}
				});
			},

			gethidemessageinfo(data) {
				var _this = this;
				handleCofirm('确认删除该聊天记录吗？', 'warning', ).then(res => {
					var par = {
						idType: _this.idType,
						Id: data.id,
					};
					hidemessageinfo(par).then((res) => {
						if (res.success) {
							this.getmessagelist()
						} else {
							_this.$message.error(res.msg);
						}
					});
				}).catch(err => {
					//console.log('err', err) // cancel
				})
			},
			//
			getmessagelist() {
				var _this = this;
				var par = {
					types: _this.idType,
					id: _this.userinfo.coM_ID,
				};
				getmessagelist(par).then((res) => {
					if (res.success) {
						_this.messageList = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			getmessageroom() {
				var _this = this;
				var par = {
					types: _this.idType,
					id: _this.rid,
				};
				getmessageinfo(par).then((res) => {
					if (res.success) {
						for (var i = 0; i < res.data.length; i++) {
							if (
								res.data[i]["typeId"] == 1 &&
								res.data[i]["memu"] != "" ||
								res.data[i]["typeId"] == 6 &&
								res.data[i]["memu"] != ""
							) {
								res.data[i]["memu"] = JSON.parse(res.data[i]["memu"]);
							}
						}
						_this.messageInfo = res.data;
						if (_this.pageScrollTo || _this.messageInfo.length != res.data.length) {
							_this.pageScrollTo = false
							setTimeout(function() {

							}, 100);
						}
					} else {
						_this.$message.error(res.msg);
					}
				});
			}, //提交内容
			SendMemu: function() {
				let _self = this;
				if (_self.Memu.length == 0) {
					_self.$message.error("不能发送空白消息！");
					return;
				}
				var par = {
					Memu: _self.Memu,
					RoomId: _self.rid,
					idType: _self.idType,
					PersonId: _self.userinfo.coM_ID,
					TypeId: 0,
				};
				addmessageinfo(par).then((res) => {
					if (res.success) {
						_self.Memu = "";
						_self.getmessageroom();
					} else {
						_self.$message.error(res.msg);
						_self.Memu = "";
					}
				});
			},
			getmessageinfo(data) {
				var _this = this;
				_this.rid = data.id
				_this.dialogTitle = isnull(data.name) ? "消息" : data.name;
				_this.getmessageroom()
			},
			//下拉框选取
			SelectChange(e, i) {
				var _this = this;
				_this.sxdata[i].list.forEach((element) => {
					if (element.cCode == e) {
						_this.sxdata[i].data = element.cName;
					}
				});
				switch (i) {
					case 0:
						_this.userinfo.coM_INDUSTRY = e;

						break;
					case 1:
						_this.userinfo.coM_ZJLB = e;

						break;
					case 2:
						_this.userinfo.coM_SCALE = e;

						break;
					case 3:
						_this.userinfo.coM_NATURE = e;

						break;
					case 4:
						_this.userinfo.coM_HYTYPE = e;

						break;
					case 5:
						_this.adddata.OF_EDU_REQ = this.sxdata[i].data;

						break;
					case 6:
						if (this.sxdata[i].data != "面议") {
							var data = this.sxdata[i].data.split("-");

							if (_this.zphdrawer) {
								_this.zphpost.cDyb = data[0];
								_this.zphpost.cDye = data[1];
							} else {
								_this.adddata.OF_SALARY = data[0];
								_this.adddata.OF_SALARYE = data[1];
							}
						} else {
							if (_this.zphdrawer) {
								_this.zphpost.cMy = 1;
							} else {
								_this.adddata.oF_SALARY_MY = 1;
							}
						}

						break;
					case 7:
						_this.adddata.OF_POSI_TYPE = e;
						break;
					default:
						break;
				}
			},

			//企业信息下拉数据
			posilistall(i) {
				var _this = this;
				var par = {
					types: _this.sxdata[i].name,
				};
				posilistall(par).then((res) => {
					if (res.success) {
						_this.sxdata[i].list = res.data.rows;
						res.data.rows.forEach((element, index) => {
							switch (i) {
								case "0":
									if (element.cCode == _this.userinfo.coM_INDUSTRY) {
										_this.sxdata[i].data = element.cName;
										_this.sxdata[i].index = index;
									}
									break;
								case "1":
									if (element.cCode == _this.userinfo.coM_ZJLB) {
										_this.sxdata[i].data = element.cName;
										_this.sxdata[i].index = index;
									}
									break;
								case "2":
									if (element.cCode == _this.userinfo.coM_SCALE) {
										_this.sxdata[i].data = element.cName;
										_this.sxdata[i].index = index;
									}
									break;
								case "3":
									if (element.cCode == _this.userinfo.coM_NATURE) {
										_this.sxdata[i].data = element.cName;
										_this.sxdata[i].index = index;
									}
									break;
								case "4":
									if (element.cCode == _this.userinfo.coM_HYTYPE) {
										_this.sxdata[i].data = element.cName;
										_this.sxdata[i].index = index;
									}
									break;
								default:
									break;
							}
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},

			setcompanylogo(url) {
				return isnull(url) ?
					require("@/assets/img/logos.png") :
					this.defimgurl + url;
			},
			setuserlogo(url) {
				var data = isnull(url) ? 0 : url.indexOf("http");
				return isnull(url) ?
					require("@/assets/img/male_img.png") :
					data < 0 ?
					this.defimgurl + url :
					url;
			},
			//侧边菜单
			navChange(i) {
				this.navindex = parseInt(i);
				localStorage.setItem("navindex", i);
				this.PageNumber = 1;
				this.ISClick = true;
				this.urlpost();
			},
			goto(url) {
				this.$router.push(url);
			},
			talk(url, data, type) {
				this.resolvewin(url, data, type ? type : 2);
			},
			jobdetail(id) {
				this.$router.push("/jobdetail?id=" + id);
			},

			loingout() {
				var _this = this;
				_this
					.$confirm("确定退出吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						localStorage.removeItem("token");
						localStorage.removeItem("userinfo");
						_this.resolvewin("/login", {
							type: 2
						}, 1);
					})
					.catch(() => {});
			},

			//当前显示数选取
			SizeChange(val) {
				this.PageSize = val;
				this.PageNumber = 1;
				this.urlpost();
			},
			//当前页选取
			CurrentChange(val) {
				this.PageNumber = val;
				this.urlpost();
			},
			//人才投递简历列表
			GetPerJobByComId() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					PageSize: _this.PageSize,
					types: "1",
					PageNumber: _this.PageNumber,
					code: _this.searchCode > 0 ? _this.searchCode : "",
					searchKey: _this.searchKey,
					showLoadType: ".resume",
				};
				GetPerJobByComId(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						});
						_this.total = res.data.total;
						_this.PerJobList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},

			//接口调用判断
			urlpost() {
				if (this.navindex == 0) {
					if (this.ISClick) {
						this.GetPerJobByComId();
						this.notreadzphjobs();
					} else {
						this.lastestact();
					}
				}
				this.PerSendClick = 0;
				this.goPost();
			},
			salary(data) {
				if (data.indexOf("-") == -1) {
					return "面议";
				} else {
					return data;
				}
			},
			cancel: function() {
				this.editinfo = JSON.parse(JSON.stringify(this.userinfo));
				this.btn = false;
			},

			goPost() {
				let distance =
					document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
				let step = distance / 30; //每步的距离
				(function jump() {
					if (distance > 0) {
						distance -= step;
						window.scrollTo(0, distance);
						setTimeout(jump, 10);
					}
				})();
			},
			gotoPost(i) {
				this.navindex = 3;
				this.types = i;
			},
			jianli(i) {
				this.navindex = 5;
				this.PerSendClick = i > 0 ? 3 : 2;
				this.goPost();
			},
			downfile() {
				this.getOperationmanualbynews();
			},
			onPreview(url) {
				this.srcList = [this.defimgurl + url];
				this.showViewer = true;
			},
			closeViewer() {
				this.showViewer = false;
			},

			refreshcompanydate(id) {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
				};
				refreshcompanydate(par).then((res) => {
					if (res.success) {
						_this.$message.success("刷新成功!");
						_this.posilist();
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			naventer(i) {
				this.navindexHover = i;
			},
			navleave() {
				this.navindexHover = null;
			},
			zphtype(i) {
				switch (i) {
					case 0:
						return "普通招聘会";
						break;
					case 1:
						return "大型招聘会";
						break;
					case 2:
						return "赴外招聘会";
						break;
					case 3:
						return "直播招聘会";
						break;
					case 99:
						return "专场招聘会";
						break;
					default:
						break;
				}
			},
			notreadzphjobs(id) {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
				};
				notreadzphjobs(par).then((res) => {
					if (res.success) {
						_this.zphjobs = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			getywstartdate() {
				var _this = this;
				var par = {
					cid: this.userinfo.coM_ID,
				};
				getywstartdate(par).then((res) => {
					if (res.success) {
						_this.ywstartdate = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			dealDisabledDate(time) {
				return this.ywstartdate ?
					new Date(this.ywstartdate) > new Date(time) :
					new Date(this.utils.dateChange(-1, this.utils.dateChange())) >
					new Date(time);
			},
			getOperationmanualbynews() {
				getOperationmanualbynews().then((res) => {
					if (res.success) {
						this.resolvewin("/info", {
							id: res.data[0].id
						}, 1);
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			infoedit() {
				this.companydetail();
				this.goPost();
			},
		},
	};
</script>
<style lang="scss" scoped>
	// 头部
	.headLine {
		width: 100%;
		padding: 35px 0 20px;
		background: white;

		.headContain {
			width: 1200px;
			margin: 0 auto;
			font-size: 14px;
			color: #666;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.headLeft {
				width: 945px;

				.search {
					width: 100%;
					height: 50px;
					line-height: 50px;
					border: 1px solid #ddd;
					box-sizing: border-box;
					position: relative;

					.line {
						height: 20px;
						border-left: 1px solid #bfc2cc;
						margin-right: 30px;
					}

					input {
						width: 500px;
						text-indent: 1em;
					}

					i {
						color: #bfc2cc;
						margin-left: 10px;
						margin-right: 30px;
					}

					.searchBTN {
						position: absolute;
						right: 0;
						top: 0;
						width: 150px;
						height: 100%;
						background: #276bf2;
						color: white;
						text-align: center;
					}
				}

				.headNav {
					margin-top: 20px;

					.navItem {
						margin-right: 90px;

						i {
							color: #bfc2cc;
							margin-left: 20px;
						}
					}
				}
			}

			.headRight {
				text-align: right;

				.tel {
					color: #276bf2;
					margin-bottom: 30px;

					i {
						margin-right: 10px;
					}
				}

				.clear {
					color: #999;
				}
			}
		}
	}

	// 内容
	.contain {
		width: 1200px;
		font-size: 14px;
		color: #999;
		margin: 10px auto 60px;

		.edit,
		.refresh {
			color: #fb6703;
			cursor: pointer;
		}

		//   左
		.left {
			width: 180px;

			.nav {
				background-color: white;
				width: 100%;

				.item {
					width: 100%;
					height: 50px;
					line-height: 50px;
					padding: 0 20px;
					display: flex;
					justify-content: space-between;
					box-sizing: border-box;
					cursor: pointer;

					.name {
						display: flex;
						align-items: center;

						img {
							width: 25px;
							margin-right: 10px;
							height: 25px;
						}
					}
				}

				.itemClick {
					box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
					color: #276bf2;

					i {
						color: #276bf2;
					}
				}

				.item:hover {
					color: #276bf2;
				}
			}

		}

		//   右
		.right {
			width: 1005px;

			.QYcenter {
				margin-bottom: 10px;

				.company {
					width: 100%;
					display: flex;

					.companyLeft {
						width: 100%;
						background-color: #fff;
						padding: 15px;
						box-sizing: border-box;
						justify-content: end;

						.logo {
							width: 80px;
							height: 80px;
							border-radius: 4px;
							overflow: hidden;
							margin-right: 15px;

							img {
								width: 100%;
							}
						}

						.companyIntro {
							width: 65%;

							.companyName {
								font-size: 24px;
								color: #333;
								margin-bottom: 10px;
							}

							.toExamine {
								display: flex;
								align-items: center;

								.examine {
									display: flex;
									align-items: center;
									padding-left: 16px;
									font-size: 14px;

									img {
										width: 20px;
										margin-right: 8px;
										cursor: text;
									}
								}
							}

							.factor {
								width: 100%;

								.factorList {
									margin-top: 10px;
									margin-right: 25px;
									line-height: 25px;
									color: #898989;
									font-size: 13px;

									img {
										width: 25px;
										height: 25px;
										float: left;
										margin-right: 10px;
									}
								}
							}

							.btnurl {
								margin-top: 15px;
								cursor: pointer;
								width: 100%;

								.btnurlList {
									height: 26px;
									line-height: 26px;
									border-radius: 20px;
									border: 1px solid;
									display: flex;
									align-items: center;
									padding: 0 15px;
									margin-right: 15px;

									img {
										width: 18px;
										height: 18px;
										float: left;
										margin-right: 10px;
									}
								}

								.wechar {
									border-color: #498CFF;
									color: #498CFF
								}

								.info {
									border-color: #08C8FB;
									color: #08C8FB
								}

								.banner {
									border-color: #02D593;
									color: #02D593
								}
							}

							b {
								color: #333;
							}
						}

						.Tips {
							.TipsM {
								margin-left: 10px;

								span {
									color: #666;
									font-size: 14px;
									display: block;
									line-height: 20px;
									margin-bottom: 10px;
								}

								b {
									color: #101010;
									font-size: 18px;
									display: block;
									line-height: 26px;
								}
							}
						}
					}
				}

				.edit,
				.refresh {
					width: 140px;
					height: 140px;
					background-color: #fff;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;

					.imgbg {
						width: 59px;
						height: 59px;
						border-radius: 50%;
						margin: 0 auto;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						justify-content: center;
					}

					img {
						width: 36px;
						height: 36px;
						display: inline-block;
					}

					span {
						text-align: center;
						display: block;
						color: #101010;
						font-size: 14px;
						margin-top: 20px;
					}
				}

				.edit .imgbg {
					background-image: linear-gradient(#15CBF7, #467DF2);
				}

				.refresh .imgbg {
					background-image: linear-gradient(#FAD96C, #FC5D3B);
				}
			}

			.introduction {
				.text {
					line-height: 2;
					margin-top: 10px;
				}
			}
		}

		.btnlist {
			box-sizing: border-box;
			overflow: hidden;

			.btns {
				width: 80px;
				margin-right: 15px;
				font-size: 12px;
				color: #666;
				text-align: center;
				cursor: pointer;
				float: left;
				margin-bottom: 15px;

				img {
					width: 60px;
					height: 60px;
					margin-bottom: 15px;
				}
			}

			a {
				font-size: 14px;
				padding: auto;
				border: 1px solid #101010;
				line-height: 33px;
				display: inline-block;
				width: 137px;
				text-align: center;
				margin-right: 12px;
				margin-top: 7px;
				color: #101010;

				span {
					font-size: 16px;
					color: #2676F9
				}
			}
		}

		.prompt {
			background-color: #fff;
			align-items: center;
			text-align: center;
			margin-bottom: 10px;
			padding: 24px 0;

			.flex6 {
				width: 200px;
				position: relative;
			}

			.flex6::after {
				content: "";
				border-right: 1px solid #eee;
				position: absolute;
				height: 36px;
				right: 0;
				top: calc(50% - 18px);
			}

			.number {
				font-size: 24px;
				color: #0096FF;
				padding: 15px 0;
			}

			span {
				font-size: 14px;
				color: #898989
			}
		}

		.message {
			box-sizing: border-box;
			width: 100%;
			display: flex;
			align-items: center;
			padding: 12px 20px;
			background-color: #fff;
			margin-bottom: 10px;
			font-size: 14px;
			color: #999999;
			justify-content: space-between;

			.message-i {
				width: 30px;
				height: 30px;
				margin-right: 10px;
			}

			.message-right {
				display: flex;
				align-items: center;
				cursor: pointer;

				.message-r {
					width: 25px;
					height: 25px;
					margin-right: 20px;
				}

				.badge {
					width: 25px;
					height: 25px;
					color: #fff;
					border-radius: 50%;
					background-color: #ff5722;
					font-size: 14px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		.recommend {
			width: 100%;

			.recTab {
				width: 100%;
				height: 60px;
				background: white;
				padding: 0 25px;
				box-sizing: border-box;
				color: #333;
				margin-bottom: 10px;

				.recTabItem {
					padding: 20px;
					margin-right: 50px;
				}

				.itemClick {
					border-bottom: 4px solid #276bf2;
					box-sizing: border-box;
					color: #276bf2;
				}

				.recTabItem:hover {
					cursor: pointer;
				}
			}

			.resume {
				width: 100%;
				padding: 20px;
				box-sizing: border-box;
				background: white;
				margin-bottom: 10px;
				cursor: pointer;

				.resumeLeft {
					width: 88%;

					.left {
						width: 60%;
						display: flex;
						border-right: 1px solid #edf1f5;

						.pic {
							width: 85px;
							height: 85px;
							border-radius: 50%;
							overflow: hidden;
							margin-right: 20px;

							img {
								width: 100%;
							}
						}

						.personal {
							display: flex;
							flex-direction: column;
							justify-content: center;

							.name {
								color: #276bf2;
								font-size: 16px;
								margin-bottom: 15px;

								.nv {
									color: rgb(220, 0, 29);
									margin-left: 10px;
								}

								.nan {
									margin-left: 10px;
								}

								.brisk {
									font-size: 14px;
									color: #666;
									margin-left: 25px;
								}
							}

							.salary {
								margin-right: 20px;
								color: #ff7630;
								font-size: 16px;
							}

							.resumeFactor {
								span {
									margin: 0 5px;
									font-size: 16px;
									font-weight: bold;
								}
							}
						}
					}

					.right {
						width: 40%;
						margin-left: 20px;

						.state {
							color: #666;
							font-size: 14px;

							.top {
								margin-bottom: 15px;
							}
						}
					}
				}

				.resumeRight {
					width: 12%;
					font-size: 14px;

					.talk {
						color: #fb6703;
						margin-bottom: 10px;

						img {
							width: 22px;
							height: 22px;
							margin-right: 5px;
						}
					}

					.collect {
						color: #276bf2;

						i {
							margin-right: 5px;
						}

						.iconoff {
							font-size: 24px;
						}

						.iconon {
							font-size: 28px;
						}
					}

					.talk:hover,
					.collect:hover {
						cursor: pointer;
					}
				}
			}

			.resume:hover {
				box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
			}
		}

		.post {
			width: 100%;
			padding: 20px 30px;
			box-sizing: border-box;
			background: white;
			margin-bottom: 10px;

			.mainTitle {
				font-size: 14px;
				color: #333;

				.main {
					font-size: 28px;
					margin-bottom: 10px;
					display: flex;
					align-items: center;
				}
			}

			.release {
				width: 120px;
				height: 35px;
				font-size: 14px;
				background: #276bf2;
				color: white;
				border-radius: 4px;
				line-height: 35px;
				text-align: center;
				cursor: pointer;
				margin-left: 10px;
			}

			.releases {
				text-align: center;
				cursor: pointer;
				display: flex;
				align-items: center;

				i {
					font-size: 22px;
					margin-right: 5px;
				}
			}
		}

		.Ctitle {
			width: 100%;
			padding: 15px 20px;
			box-sizing: border-box;
			background: white;
			font-size: 16px;
			color: #333;
			font-weight: bold;
			margin-bottom: 10px;
		}

		.module {
			width: 100%;
			box-sizing: border-box;
			background: white;
			padding: 20px;
			font-size: 14px;
			color: #333;
			margin-bottom: 10px;

			.moduleEdit {
				color: #666;
			}

			.moduleEdit:hover {
				cursor: pointer;
			}

			.moduleTitle {
				font-size: 16px;
				color: #333;
				font-weight: bold;
				margin-bottom: 25px;

				img {
					width: 30px;
					margin-right: 10px;
				}
			}

			.label {
				width: 100%;

				textarea {
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					padding: 10px 5px;
					width: 100%;
				}

				.labelLine {
					width: 50%;
					margin-bottom: 20px;
					position: relative;

					.labelName {
						width: 90px;
					}

					input {
						height: 40px;
						box-sizing: border-box;
						border: 1px solid rgba(193, 189, 189, 0.4);
						border-radius: 4px;
						text-indent: 1em;
						width: 260px;
					}

					.labelselect {
						height: 40px;

						width: 260px;

						.el-input__inner {
							width: 260px;
						}
					}

					b {
						color: red;
					}

					i {
						color: #bfc2cc;
						position: absolute;
						left: 325px;
					}
				}
			}

			.moduleBTN {
				display: flex;
				justify-content: flex-end;
				margin-top: 20px;

				.cancel,
				.preservation {
					width: 120px;
					height: 35px;
					line-height: 35px;
					text-align: center;
					margin: 0 15px;
				}

				.cancel {
					border: 1px solid #276bf2;
					box-sizing: border-box;
					color: #276bf2;
					cursor: pointer;
				}

				.preservation {
					background: #276bf2;
					color: white;
					cursor: pointer;
				}
			}

			.license {
				margin: 15px 0;
				width: 280px;
				height: 160px;
				line-height: 160px;
				text-align: center;
				overflow: hidden;
				color: #999;
				font-size: 20px;
				background: #f8f8f8;
			}
		}
	}

	.page {
		width: 100%;
		background: white;
		height: 80px;
		line-height: 80px;

		.el-pagination {
			width: 100%;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			::v-deep.el-pager li {
				vertical-align: middle;
				color: #666;
				margin: 0 10px;
				font-weight: normal;
			}

			::v-deep.el-pager li.active {
				color: #fff;
				background: #276bf2;
				border-radius: 4px;
			}

			::v-deep.el-pager li:hover {
				color: #276bf2;
			}
		}
	}

	.box {
		width: 100%;
		padding: 12px 20px;
		background-color: #fff;
		margin-bottom: 10px;
		box-sizing: border-box;

		.box-head {
			display: flex;
			margin-bottom: 10px;

			.title {
				font-size: 18px;
				color: #010101
			}

			span {
				font-size: 14px;
				color: #898989;
			}
		}
	}

	.business {

		.business-top {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.botton-li {
				display: flex;
				align-items: center;

				.botton {
					width: 100px;
					height: 35px;
					line-height: 35px;
					text-align: center;

					color: white;
					font-size: 14px;
					border-radius: 4px;
					cursor: pointer;
					margin-left: 10px;
				}

				.btn-b {
					background: #276bf2;
				}

				.btn-a {
					background: #67c23a;
				}

				.btn-c {
					background: #909399;
				}
			}
		}


		.business-left {
			width: 100%;

			.el-table ::v-deep tbody tr>td {
				border: 0;
			}

			.el-table ::v-deep tbody tr:hover>td {
				background: #fff !important
			}

			.business-btn {
				display: flex;
				align-items: center;
				justify-content: space-around;

				.business-t {
					display: flex;
					align-items: center;
					font-size: 14px;
					margin-left: 5px;
					cursor: pointer;

					img {
						width: 20px;
						height: 20px;
						margin-right: 5px;
					}
				}
			}
		}

		.business-right {
			width: 30%;

			.botton-li {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.botton {
					color: #FE892C;
					font-size: 14px;
					line-height: 48px;
					margin-left: 15px;
				}
			}

		}
	}

	.hui {
		font-size: 12px;
	}

	.han {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #666;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}

	.avatar {
		width: 100px;
		height: 100px;
		display: block;
	}

	.el-cascader {
		width: 300px;
	}

	.rowclass {
		font-size: 13px;
	}

	/* .headerrowclass {} */

	.popoverinfo {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.popoverinfo-img {
		width: 250px;
		height: 250px;
		margin-bottom: 10px;
	}

	.popoverinfo-span {
		font-size: 14px;
		color: #666;
		margin-top: 10px;
	}

	.popoverinfo-span span {
		color: #409eff;
		cursor: pointer;
	}

	.dialog-footer {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.footer-btn {
		width: 150px;
	}

	.messagedetail .el-dialog__header {
		padding: 10px;
	}

	.messagedetail .el-dialog__headerbtn {
		top: 10px
	}

	.messagedetail .el-dialog__body {
		padding: 0 !important;
	}

	.innerbox::-webkit-scrollbar {
		width: 4px;
		/*height: 4px;*/
	}

	.innerbox::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: rgba(0, 0, 0, 0.2);
	}

	.innerbox::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: rgba(0, 0, 0, 0.1);

	}
</style>
<style lang="scss" scoped>
	// .scrolly {}

	.messagedetail {
		box-sizing: border-box;

		.message-left {
			background-color: #e2e0df;
			box-sizing: border-box;
			width: 250px;
			height: 600px;
			overflow-y: auto;

			.message-list {
				.item {
					padding: 10px;
					line-height: 24px;
					position: relative;

					.mc {
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;

						a {
							position: absolute;
							bottom: 10px;
							right: 5px;
						}
					}

					.Logo {
						width: 50px;
						height: 50px;
						border-radius: 50px;
						overflow: hidden;

						img {
							width: 50px;
							height: 50px;
						}
					}

					.name {
						color: #101010;
						overflow: hidden;
						width: 60%;
						word-break: keep-all;
						white-space: nowrap;
						text-overflow: ellipsis;
					}

					.time {
						color: #898989
					}

					.info {
						color: #898989;
						overflow: hidden;
						width: 80%;
						word-break: keep-all;
						white-space: nowrap;
						text-overflow: ellipsis;
					}

					.badge {
						margin-right: 15px;

						.badge-i {
							background-color: #0089FF;
							width: 17px;
							height: 17px;
							display: flex;
							align-items: center;
							justify-content: center;
							color: #fff;
							border-radius: 50px;
						}
					}
				}

				.item:hover {
					background-color: #c6c6c5;
				}
			}
		}

		.message-right {
			background-color: #f5f5f5;
			box-sizing: border-box;
			width: 610px;
		}
	}

	.message-box {
		.message-title {
			line-height: 40px;
			padding: 0 10px;
		}

		// 内容
		.message-detail {
			display: flex;
			flex-direction: column;
			position: relative;
			background: #ccc;
			height: 520px;
			overflow-y: auto;

			.message-scroll {
				flex: 1;
				padding: 10px;
			}
		}

		.postList {
			margin: 10px;
			background: white;
			font-size: 14px;
			padding: 15px;

			image {
				width: 30px;
				height: 18px;
			}

			.tel {
				width: 85%;
				font-size: 14px;
				color: #333;
			}

			.portrait {
				width: 30px;
				height: 30px;
				border-radius: 50px;
				overflow: hidden;
				margin-right: 10px;
				line-height: 30px;
				text-align: center;
				color: #fff;

				image {
					width: 30px;
					height: 30px;
				}
			}

			.factor {
				background: #f5f8ff;
				padding: 3px 6px;
				color: #1977fb;
				font-size: 14px;
				margin: 10px 0;
				margin-right: 10px;
			}

			.postInfo {
				font-size: 14px;
				color: #666;
				margin-top: 10px;
			}

			.name {
				font-weight: bold;
				color: #333;
				margin-right: 10px;
			}

			.salarr {
				color: #ff6615;
				font-weight: bold;
			}
		}

		.time {
			font-size: 14px;
			color: #666;
			text-align: center;
			margin: 15px 0;
		}

		.popup {
			width: 280px;
			margin: 10px auto;
			border-radius: 10px;
			background: white;
			padding: 15px 20px 0;
			box-sizing: border-box;
			font-size: 14px;
			color: #333;

			.pic {
				width: 40px;
				height: 40px;
				border-radius: 4px;
				overflow: hidden;
				margin-right: 10px;

				image {
					width: 40px;
					height: 40px;
				}
			}

			.flex1 {
				padding-bottom: 15px;
			}

			.btn {
				border-top: 1px solid #eee;
				padding: 10px;

				.btnlist {
					width: 50%;
					text-align: center;
					font-size: 14px;
					color: #1c7efe;
				}

				.btnlist:first-child {
					border-right: 1px solid #eee;
				}
			}
		}

		.message-hr-img {
			margin-right: 12px;
		}

		.message-hr-news {
			padding: 20px;
			max-width: 450px;
			border-radius: 10px;
			overflow: hidden;
			background: #fff;
			color: #000;
		}

		.message-hr-news text {
			table-layout: fixed;
			word-wrap: break-all;
			word-break: normal;
			overflow: hidden;
		}

		.message-me {
			display: flex;
			justify-content: flex-end;
		}

		.message-me-img {
			margin-left: 12px;
		}

		.message-me-news {
			padding: 20px;
			max-width: 450px;
			border-radius: 10px;
			overflow: hidden;
			background: #1c7efe;
			color: #fff;
		}

		.message-me-news text {
			table-layout: fixed;
			word-wrap: break-word;
			word-break: normal;
			overflow: hidden;
		}

		.dialog {
			margin: 10px 0;
			display: flex;
			align-items: top;
			padding: 0;

			.portrait {
				width: 30px;
				height: 30px;
				border-radius: 50px;
				text-align: center;
				line-height: 30px;
				margin-top: 5px;
				overflow: hidden;
				color: #fff;

				img {
					width: 100%;
					height: 100%;
				}

				image {
					width: 30px;
					height: 30px;
				}
			}

			.dialogCont {
				max-width: 225px;
				padding: 10px;
				box-sizing: border-box;
				font-size: 14px;
				border-radius: 4px;
			}
		}

		.writing {
			width: 100%;
			background: white;

			input {
				height: 40px;
				width: 100%;
				background: #f8f8f8;
				border: none;
				padding: 0;
				text-indent: 1em;
				font-size: 14px;
				color: #666;
				border-radius: 2px;
			}
		}

		.mf-icon {
			height: 40px;
			width: 60px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			background-color: #1389ff;
			color: #FFFFFF;
			border: none;
			border-radius: 0px;
		}

		.more {
			font-size: 14px;
			color: #333;
			line-height: 45px;
			text-align: center;
		}
	}

	.qycolor0 {
		background-color: #0098fe;
	}

	.qycolor1 {
		background-color: #fe7270;
	}

	.qycolor2 {
		background-color: #ff9a65;
	}

	.qycolor3 {
		background-color: #6bb7b2;
	}

	.qycolor4 {
		background-color: #9bcd35;
	}

	.qycolor5 {
		background-color: #db4e36;
	}

	.qycolor6 {
		background-color: #01a758;
	}

	.qycolor7 {
		background-color: #8c53e6;
	}

	.qycolor8 {
		background-color: #5ed9a8;
	}

	.qycolor9 {
		background-color: #49c2e8;
	}
</style>
