<template>
	<div>
		<div class="Ctitle">搜索简历</div>
		<div>
			<div class="search">
				<ul class="search-ul">
					<li>
						<label class="layui-text">关键字：</label>
						<input v-model="searchKey" placeholder="请输入关键字" class="search-input"></input>
					</li>
					<li class="select-div">
						<label class="layui-text">性别：</label>
						<el-select v-model="searcList[0].clcik" placeholder="不限">
							<el-option v-for="(item, index) in searcList[0].data" :key="index" :value="item.value"
								:label="item.name"></el-option>
						</el-select>
					</li>
					<li class="select-div">
						<label class="layui-text">学历：</label>
						<el-select v-model="searcList[1].clcik" placeholder="不限">
							<el-option v-for="(item, index) in searcList[1].data" :key="index" :value="item.value"
								:label="item.name"></el-option>
						</el-select>
					</li>
					<li class="select-div">
						<label class="layui-text">活跃时间：</label>
						<el-select v-model="searcList[2].clcik" placeholder="不限">
							<el-option v-for="(item, index) in searcList[2].data" :key="index" :value="item.value"
								:label="item.name"></el-option>
						</el-select>
					</li>
					<!-- <li>
						<label class="layui-text">验证码：</label>
						<input v-model="code" placeholder="请输入验证码" class="search-input code"></input>
						<span id="canvasCode" class="code"></span>
					</li> -->
					<li class="clickSearch">
						<el-button type="primary" class="layui-btn layui-btn-primary" v-preventReClick
							@click="search()">搜索</el-button>
					</li>
				</ul>
			</div>

			<div class="resumelist">
				<template>
					<el-table :data="PerJobList" stripe style="width: 100%" header-row-class-name="headerrowclass"
						row-class-name="rowclass">
						<el-table-column type="selection">
						</el-table-column>
						<el-table-column prop="service_Name" label="基本信息(姓名/性别/工作年限/学历)">
							<template slot-scope="scope">
								<div class="resume">
									<img :src="scope.row.peR_IMG_PATH ? setuserlogo(scope.row.peR_IMG_PATH) : 
									 	scope.row.peR_GENDER=='女'? require('@/assets/img/nv.png'): require('@/assets/img/male_img.png')" alt="" />
									<span @click="talk('/resumedetail', { id: scope.row.peR_ID})"
										style="color:#101010;cursor: pointer;">{{scope.row.peR_NAME
										? scope.row.isDown==0&&scope.row.isJobs==0? utils.formatName(scope.row.peR_NAME):scope.row.peR_NAME
										: scope.row.peR_ACCOUNT
										? scope.row.isDown==0&&scope.row.isJobs==0? utils.formatName(scope.row.peR_ACCOUNT):scope.row.peR_ACCOUNT
										: "神秘人"
									}}</span>
									<span>|</span>
									<span>{{ scope.row.peR_GENDER ? scope.row.peR_GENDER : "男" }}</span>
									<span>|</span>
									<span>{{scope.row.peR_WORK_YEARS? scope.row.peR_WORK_YEARS + "年" : "无"}}</span>
									<span>|</span>
									<span>{{ scope.row.per_edu ? scope.row.per_edu : "高中及以下" }}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="求职状态" width="150">
							<template slot-scope="scope">
								{{scope.row.peR_PER_STATUS ? scope.row.peR_PER_STATUS: "考虑就业机会"}}
							</template>
						</el-table-column>
						<el-table-column label="活跃时间" width="150">
							<template slot-scope="scope">
								{{ scope.row.peR_LASTLOAD ? utils.stringToDateTime(scope.row.peR_LASTLOAD,1) : "" }}
							</template>
						</el-table-column>
						<el-table-column align="center" fixed="right" label="操作" width="80">
							<template slot-scope="scope">
								<div class="business-btn flex">
									<div class="talk flexc blue" @click="drawerChange(scope.row.peR_ID)">
										查看
									</div>
									<div v-if="scope.row.isFav == 0" class="collect flexc blue"
										@click="favuser(scope.row.peR_ID)">
										收藏
									</div>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</div>
			<div class="page" v-if="PerJobList.length > 0 && total > 10">
				<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="pageNumber"
					:page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		favuser,
		cancelfavuser,
		getpersonpage,
		downloadper,
	} from "@/api/company";
	import {
		Getposilist,
		getGVerify
	} from "@/api/home";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	import {
		CodeToText
	} from "element-china-area-data";
	export default {
		data() {
			return {
				defimgurl: global.baseimgurl,
				pageNumber: 1,
				pageSize: 10,
				total: 0,
				searchKey: "",
				code: "",
				PerJobList: [],
				isCall: 0,
				isRshow: false,
				rid: 0,
				GVerifyCode: null,
				searcList: [{
						clcik: "",
						name: "性别",
						value: "不限",
						data: [{
								name: "不限",
								value: ""
							},
							{
								name: "男",
								value: "男"
							},
							{
								name: "女",
								value: "女"
							},
						],
					},
					{
						clcik: "",
						name: "学历",
						value: "不限",
						data: [{
							name: "不限",
							value: ""
						}],
					},
					{
						clcik: "",
						name: "活跃时间",
						value: "不限",
						data: [{
								name: "不限",
								value: ""
							},
							{
								name: "近三天",
								value: 1
							},
							{
								name: "近一周",
								value: 2
							},
							{
								name: "近一个月",
								value: 3
							},
							{
								name: "近三个月",
								value: 4
							},
							// {
							// 	name: "近半年",
							// 	value: 5
							// },
						],
					},
				],
			}
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			this.Getposilists()
		},
		watch: {},
		mounted() {
			//this.getGVerify()
			//this.Getposilists()
			this.getpersonpage()
		},
		methods: {
			drawerChange(id) {
				this.$parent.isCall = 0;
				this.$parent.uid = id;
				this.$parent.isRshow = !this.$parent.isRshow;
			},
			getGVerify() {
				this.GVerifyCode = getGVerify({
					id: "canvasCode",
					width: "70",
					height: "30",
					fontSize: "26",
					numSize: 4,
					type: "blend",
				});
			},
			CodeToText(value) {
				return CodeToText[value];
			},
			handleCommand(command, index) {
				this.searcList[index].value = this.searcList[index].data[command].name;
				this.searcList[index].clcik = this.searcList[index].data[command].value;
			},
			Getposilists() {
				var _this = this;
				Getposilist({
					types: "学历"
				}).then((res) => {
					if (res.success) {
						_this.educationlist = res.data.rows;
						res.data.rows.forEach((element) => {
							if (element.cName != "不限") {
								this.searcList[1].data.push({
									name: element.cName,
									value: element.cCode,
								});
							}
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			search() {
				var _this = this;
				// if (isnull(_this.code)) {
				// 	_this.$message.error("请输入验证码");
				// 	return false;
				// }
				this.getpersonpage()
				//this.GVerifyCode.refresh();
			},
			//人才简历列表
			getpersonpage() {
				var _this = this;
				_this.searcList.forEach((element) => {

				})

				var par = {
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					id: this.userinfo.coM_ID,
					searchKey: _this.searchKey,
					gender: _this.searcList[0].clcik,
					edu: _this.searcList[1].clcik,
					code: _this.searcList[2].clcik,
					searchKey: _this.searchKey,
					showLoadType: ".resumelist",
				};
				getpersonpage(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						});
						_this.total = res.data.total;
						_this.PerJobList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//当前显示数选取
			SizeChange(val) {
				this.PageSize = val;
				this.PageNumber = 1;
				this.getpersonpage();
			},
			//当前页选取
			CurrentChange(val) {
				this.PageNumber = val;
				this.getpersonpage();
			},
			//人才收藏
			favuser(id) {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					uid: id,
				};
				favuser(par).then((res) => {
					if (res.success) {
						this.getpersonpage();
						_this.$message.success("简历已收藏!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//取消人才收藏
			cancelfavuser(id) {
				var _this = this;
				handleCofirm('确认取消收藏该简历吗？', 'warning', ).then(res => {
					var par = {
						id: this.userinfo.coM_ID,
						uid: id,
					};
					cancelfavuser(par).then((res) => {
						if (res.success) {
							this.getpersonpage();
							_this.$message.success("已取消收藏!");
						} else {
							_this.$message.error(res.msg);
						}
					});
				}).catch(err => {
					//console.log('err', err) // cancel
				})
			},

			//下载简历
			downloadper() {
				var _this = this;
				downloadper({
					id: this.userinfo.coM_ID,
					uid: _this.pid,
				}).then((res) => {
					if (res.success) {
						this.getresumebyid();
						_this.$message.success("简历已下载!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			talk(url, data) {
				this.resolvewin(url, data, 2);
			},
			setuserlogo(url) {
				var data = url.indexOf("http");
				return isnull(url) ?
					require("@/assets/img/logos.png") :
					data < 0 ?
					this.defimgurl + url :
					url;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.Ctitle {
		width: 100%;
		padding: 15px 20px;
		box-sizing: border-box;
		background: white;
		font-size: 16px;
		color: #333;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.code {
		width: 80px !important;
	}

	#canvasCode {
		height: 30px;
		display: flex;
	}

	#verifyCanvas {}

	.resumelist {
		color: #898989;

		.resume {
			display: flex;
			line-height: 36px;

			img {
				width: 36px;
				height: 36px;
			}

			span {
				line-height: 36px;
				margin-left: 10px;
			}
		}

		.talk,
		.collect {
			cursor: pointer;
		}
	}

	.page {
		width: 100%;
		background: white;
		height: 80px;
		line-height: 80px;

		.el-pagination {
			width: 100%;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			::v-deep.el-pager li {
				vertical-align: middle;
				color: #666;
				margin: 0 10px;
				font-weight: normal;
			}

			::v-deep.el-pager li.active {
				color: #fff;
				background: #276bf2;
				border-radius: 4px;
			}

			::v-deep.el-pager li:hover {
				color: #276bf2;
			}
		}
	}
</style>
